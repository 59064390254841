import React from "react";
import "./Assets/StylesTemplate/Global.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { Routes, Route, Navigate } from "react-router-dom";
import { routes } from "./Utils/routes";

import PrivateRoute from "./PrivateRoute";
import AdminLayout from "./Layouts/AdminLayout";
import SignIn from "./Views/Onboarding/SignIn";
import ForgotPassword from "./Views/Onboarding/ForgotPassword";
import Enterprises from "./Views/Admin/Enterprises/Enterprises";
import Devices from "./Views/Admin/Devices/Devices";
import Policies from "./Views/Admin/Policies/Policies";
import PolicyItem from "./Views/Admin/Policies/PolicyItem/PolicyItem";
import ErrorPage from "./Views/ErrorPage/ErrorPage";
import GenerateEnrollmentToken from "./Views/Admin/GenerateEnrollmentToken/GenerateEnrollmentToken";
import EnterpriseProvider from "./Context/EnterpriseProivder";

const App: React.FC = (): JSX.Element => {
   return (
      <>
         <ToastContainer
            position="top-center"
            autoClose={5000}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            closeButton={true}
         />

         <EnterpriseProvider>
            <Routes>
               {/* onboarding */}
               <Route path={routes.signIn} element={<SignIn />} />
               <Route path={routes.forgotPassword} element={<ForgotPassword />} />
               {/* admin routes */}
               <Route
                  path={routes.enterprises}
                  element={
                     <PrivateRoute>
                        <Enterprises />
                     </PrivateRoute>
                  }
               />
               {/* routes with admin layout */}
               <Route element={<AdminLayout />}>
                  <Route
                     path={routes.devices}
                     element={
                        <PrivateRoute>
                           <Devices />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path={routes.policies}
                     element={
                        <PrivateRoute>
                           <Policies />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path={`${routes.policy}/:id`}
                     element={
                        <PrivateRoute>
                           <PolicyItem />
                        </PrivateRoute>
                     }
                  />
                  <Route
                     path={routes.generateEnrollmentToken}
                     element={
                        <PrivateRoute>
                           <GenerateEnrollmentToken />
                        </PrivateRoute>
                     }
                  />
               </Route>
               <Route path={routes.errorPage} element={<ErrorPage />} />
               <Route path="*" element={<Navigate to={routes.errorPage} />} />
            </Routes>
         </EnterpriseProvider>
      </>
   );
};

export default App;
