import React, { useRef } from "react";
import styles from "./Onboarding.module.scss";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Utils/routes";
import { emailPattern } from "../../Utils/regexPatterns";
import { toast } from "react-toastify";
import { AiOutlineArrowLeft as ArrowLeft } from "react-icons/ai";
import { emptyInputsError, emailNotValid, emailSentSuccess, errorOccured } from "../../Utils/notifications";

const ForgotPassword: React.FC = (): JSX.Element => {
   const emailRef = useRef<HTMLInputElement>(null);

   const navigate = useNavigate();

   const handleSendEmail = (e: React.FormEvent): void => {
      e.preventDefault();

      // input values
      const emailInputValue = emailRef.current!.value;

      // validate values
      const validEmail = emailPattern.test(emailInputValue);

      try {
         if (emailInputValue === "") {
            toast.error(emptyInputsError);
         } else if (!validEmail) {
            toast.error(emailNotValid);
         } else {
            toast.success(emailSentSuccess);
         }
      } catch (error) {
         toast.error(errorOccured);
         console.log(error);
      }
   };

   return (
      <div className={`d-flex justify-content-center align-items-center ${styles.onboarding}`}>
         <div className={`mainContainer d-flex flex-column justify-content-center align-items-center`}>
            <form
               className={`d-flex flex-column align-items-center justify-content-center ${styles.formContainer}`}
               onSubmit={e => handleSendEmail(e)}
            >
               <h2 className={`mb-5 text-center nova-bold size-40 novaBold900 textMagentaGradient`}>Forgot password</h2>

               <label className={styles.inputLabel}>
                  <span className={`size-20 novaBold700`}>Email</span>
                  <input
                     ref={emailRef}
                     value="johndoe@gmail.com"
                     type="email"
                     className={`mt-2 ${styles.loginInput}`}
                     placeholder="Enter email"
                     onChange={() => ""}
                  />
               </label>

               <button className={styles.mainActionButton} onClick={e => handleSendEmail(e)}>
                  Send email
               </button>

               <div className="mt-5 w-100 d-flex justify-content-center">
                  <div
                     className="d-flex align-items-center pointer size-16 weight-500 highlightSpan"
                     onClick={() => navigate(routes.signIn)}
                  >
                     <ArrowLeft className="me-2" />
                     <span>Go back</span>
                  </div>
               </div>
            </form>
         </div>
      </div>
   );
};

export default ForgotPassword;
