import React, { useState } from "react";
import styles from "../Policies.module.scss";
import { errorOccured } from "../../../../Utils/notifications";
import { toast } from "react-toastify";
import axios from "axios";
import { useEnterprise } from "../../../../Context/EnterpriseProivder";
import { IPolicy } from "../../../../Interfaces/GlobalInterfaces";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { hexEncode } from "../../../../Utils/hex";
import { routes } from "../../../../Utils/routes";

type IProps = {
   handleNextStep: () => void;
   name: string;
   setName: React.Dispatch<React.SetStateAction<string>>;
};

const FirstStep: React.FC<IProps> = ({ handleNextStep, name, setName }): JSX.Element => {
   const [disabled, setDisabled] = useState<boolean>(false);

   const enterprise = useEnterprise();
   const navigate = useNavigate();

   const seePolicy = (policy: IPolicy): void => {
      let policyItem = policy.name!;
      navigate(routes.policy + "/" + hexEncode(policyItem));
   };

   const Msg = ({ closeToast, toastProps, policy }: any): any => (
      <div>
         <p className="mb-2">This Policy already exists.</p>
         <div className="d-flex flex-wrap gap-2">
            <Button className="btn btn-sm btn-info" onClick={() => seePolicy(policy)}>
               See Policy
            </Button>
            <Button className="btn btn-sm btn-secondary" onClick={closeToast}>
               Close
            </Button>
         </div>
      </div>
   );

   const getStringData = (data: string): string => {
      const result = data.split("/").slice(3).toString();
      return result;
   };

   // name input handler
   const handleChange = (event: React.FormEvent<HTMLInputElement>): void => {
      setName(event.currentTarget.value);
   };

   // get policies list
   const fetchPolicies = async (): Promise<IPolicy[] | undefined> => {
      try {
         const result = await axios.get<IPolicy[]>(process.env.REACT_APP_HIDR_URL + "/policies/list", {
            params: {
               parent: enterprise?.name,
            },
            headers: {
               Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
         });
         return result.data;
      } catch (error) {
         if (error && error.response) {
            toast.error(error.response.data.message);
         } else {
            toast.error(errorOccured);
            console.log(error);
         }
      }
   };

   const handleCreatePolicy = async (): Promise<void> => {
      if (!name) {
         toast.error("Name can't be empty");
      } else {
         const policies = await fetchPolicies();
         if (policies?.find((policy) => getStringData(policy.name!) === name)) {
            toast(<Msg policy={policies?.find((policy) => getStringData(policy.name!) === name)} />, {
               toastId: "duplicate",
            });
         } else {
            setDisabled(true);
            try {
               const result = await axios.post(
                  process.env.REACT_APP_HIDR_URL + "/policies",
                  {
                     name: `${enterprise?.name}/policies/${name}`,
                  },
                  {
                     headers: {
                        Authorization: "Bearer " + localStorage.getItem("jwt"),
                     },
                  }
               );

               if (result.status === 200 || result.status === 201) {
                  toast.success(`You have created "${name}" policy succesfully 💥`);
                  handleNextStep();
               }
            } catch (error) {
               if (error && error.response) {
                  toast.error(error.response.data.message);
               } else {
                  toast.error(errorOccured);
                  console.log(error);
               }
            } finally {
               setDisabled(false);
            }
         }
      }
   };

   return (
      <React.Fragment>
         <h4 className="mb-5 size-30 novaBold700">Generate Policy</h4>
         <label className={`mb-4 ${styles.inputLabel}`}>
            <span className="mb-2 size-18 novaMedium">Name</span>
            <input name="name" type="text" className={styles.policyInput} onChange={handleChange} value={name} />
         </label>
         <button disabled={disabled} onClick={handleCreatePolicy} className={`${styles.mainActionButton} mt-4`}>
            Next
         </button>
      </React.Fragment>
   );
};

export default FirstStep;
