import React, { useState } from "react";
import styles from "./Header.module.scss";
import { routes } from "../../Utils/routes";
import { useNavigate, useLocation } from "react-router-dom";
// import { MdBusinessCenter as EnterpriseIcon } from "react-icons/md";
import { HiDeviceTablet as DevicesIcon } from "react-icons/hi";
import { GiHamburgerMenu as MenuIcon } from "react-icons/gi";
import { MdPolicy as PoliciesIcon } from "react-icons/md";
import { FaUser as UserIcon } from "react-icons/fa";
import { MdGeneratingTokens as GenerateEnrollmentTokenIcon } from "react-icons/md";

interface Props {
   toggleMenu: () => void;
}

const Header: React.FC<Props> = ({ toggleMenu }): JSX.Element => {
   const [showUserDropdown, setShowUserDropdown] = useState<boolean>(() => false);

   const navigate = useNavigate();
   const location = useLocation();

   const toggleUserDropdown = (): void => setShowUserDropdown(!showUserDropdown);

   const handleLogOut = (): void => {
      localStorage.removeItem("jwt");

      navigate(routes.signIn);
   };

   let heading: JSX.Element;

   switch (location.pathname) {
      // case routes.enterprises:
      //    heading = (
      //       <>
      //          <EnterpriseIcon className={styles.headerIcon} />
      //          <h2 className={`mb-0 textMagentaGradient novaBold900 ${styles.heading}`}>Enterprises</h2>
      //       </>
      //    );
      //    break;

      case routes.devices:
         heading = (
            <>
               <DevicesIcon className={styles.headerIcon} />
               <h2 className={`mb-0 textMagentaGradient novaBold900 ${styles.heading}`}>Devices</h2>
            </>
         );
         break;

      case routes.policies:
         heading = (
            <>
               <PoliciesIcon className={styles.headerIcon} />
               <h2 className={`mb-0 textMagentaGradient novaBold900 ${styles.heading}`}>Policies</h2>
            </>
         );
         break;

      case routes.generateEnrollmentToken:
         heading = (
            <>
               <GenerateEnrollmentTokenIcon className={styles.headerIcon} />
               <h2 className={`mb-0 textMagentaGradient novaBold900 ${styles.heading}`}>Token</h2>
            </>
         );
         break;

      default:
         heading = <h2 className={`mb-0 textMagentaGradient novaBold900 ${styles.heading}`}>Admin panel</h2>;
   }

   return (
      <div className={`bottomShadow ${styles.mainHeader}`}>
         <div className={`headerContainer h-100 d-flex align-items-center justify-content-between`}>
            <div className="d-flex align-items-center">{heading}</div>

            <div className={styles.controlsContainer}>
               <div className={styles.userIcon} onClick={toggleUserDropdown}>
                  <UserIcon />
               </div>

               {/* user dropdown */}
               <div className={`${styles.userDropdown} ${showUserDropdown && styles.dropMenuActive}`}>
                  <h5>You logged in as:</h5>
                  <span className={`text-break`}>{`robert@gmail.com`}</span>

                  <button className={styles.logoutButton} onClick={handleLogOut}>
                     Logout
                  </button>
               </div>

               <MenuIcon className={styles.menuIcon} onClick={toggleMenu} />
            </div>
         </div>
      </div>
   );
};

export default Header;
