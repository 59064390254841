import React from "react";
import styles from "./PolicyModal.module.scss";
import { Modal } from "react-bootstrap";
import { IPolicy } from "../../Interfaces/GlobalInterfaces";
import { FaCopy as CopyIcon } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

interface Props {
   show: boolean;
   onHide: () => void;
   policyItem: IPolicy;
}

const PolicyModal: React.FC<Props> = ({ show, onHide, policyItem }): JSX.Element => {
   const handleCopy = () => toast.success("JSON copied successfully");

   const getStringData = (data: string): string => {
      const result = data.split("/").slice(3).toString();
      return result;
   };

   return (
      <Modal centered show={show} onHide={onHide} size="lg">
         <Modal.Header closeButton>
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
               <h4 className="size-26">Policy</h4>
               <p className="magentaText size-20 novaBold700">
                  #{" "}
                  <span className="darkText novaRegular">
                     {policyItem.name ? getStringData(policyItem.name) : "no code"}
                  </span>
               </p>
            </div>
         </Modal.Header>

         <Modal.Body>
            <div className={styles.jsonContainer}>
               <pre className={`whiteText`}>{JSON.stringify(policyItem, null, 2)}</pre>
            </div>
            <div className="mt-4 w-100 d-flex justify-content-end">
               <CopyToClipboard text={JSON.stringify(policyItem)} onCopy={handleCopy}>
                  <button className={`d-flex align-items-center justify-content-center pointer ${styles.copyButton}`}>
                     <CopyIcon className="me-2" />
                     <span>Copy</span>
                  </button>
               </CopyToClipboard>
            </div>
         </Modal.Body>
      </Modal>
   );
};

export default PolicyModal;
