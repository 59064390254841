import React from "react";
import styles from "./Sidebar.module.scss";
import { NavLink } from "react-router-dom";
import { routes } from "../../Utils/routes";
import { MdBusinessCenter as EnterpriseIcon } from "react-icons/md";
import { HiDeviceTablet as DevicesIcon } from "react-icons/hi";
import { MdPolicy as PoliciesIcon } from "react-icons/md";
import { MdGeneratingTokens as GenerateEnrollmentTokenIcon } from "react-icons/md";

interface Props {
   showMenu: boolean;
   toggleMenu: () => void;
}

const Sidebar: React.FC<Props> = ({ showMenu, toggleMenu }): JSX.Element => {
   return (
      <nav className={`${styles.sidebar} ${showMenu && styles.activeSidebar}`}>
         <NavLink
            to={routes.enterprises}
            className={(navData) => (navData.isActive ? styles.activeLink : styles.linkItem)}
            onClick={toggleMenu}
         >
            <EnterpriseIcon />
            <span>Enterprises</span>
         </NavLink>

         <NavLink
            to={routes.devices}
            className={(navData) => (navData.isActive ? styles.activeLink : styles.linkItem)}
            onClick={toggleMenu}
         >
            <DevicesIcon />
            <span>Devices</span>
         </NavLink>

         <NavLink
            to={routes.policies}
            className={(navData) => (navData.isActive ? styles.activeLink : styles.linkItem)}
            onClick={toggleMenu}
         >
            <PoliciesIcon />
            <span>Policies</span>
         </NavLink>

         <NavLink
            to={routes.generateEnrollmentToken}
            className={(navData) => (navData.isActive ? styles.activeLink : styles.linkItem)}
            onClick={toggleMenu}
         >
            <GenerateEnrollmentTokenIcon />
            <span>Token</span>
         </NavLink>
      </nav>
   );
};

export default Sidebar;
