import React from 'react';
import { ChildrenProps } from './Interfaces/GlobalInterfaces';
import { Navigate } from 'react-router-dom';
import { routes } from './Utils/routes';
import { useLocalStorage } from './Helpers/useLocalStoarge';

const PrivateRoute: React.FC<ChildrenProps> = ({ children }): JSX.Element => {
    const [isLogin] = useLocalStorage("jwt", "");

    return isLogin ? children : <Navigate to={routes.signIn} />;
};

export default PrivateRoute;