import React, { useState, useEffect } from "react";
// import styles from "./Devices.module.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { errorOccured } from "../../../Utils/notifications";
import PulseLoader from "react-spinners/PulseLoader";
import { Table } from "react-bootstrap";
import Moment from "react-moment";
import DeviceModal from "../../../Components/DeviceModal/DeviceModal";
import { IDevice } from "../../../Interfaces/GlobalInterfaces";
import { useEnterprise } from "../../../Context/EnterpriseProivder";

const Devices: React.FC = (): JSX.Element => {
   const [devicesList, setDevicesList] = useState<IDevice[]>([]);
   const [loading, setLoading] = useState<boolean>(true);
   const [activeDevice, setActiveDevice] = useState<IDevice>({});
   const [showDeviceModal, setShowDeviceModal] = useState<boolean>(() => false);

   const enterprise = useEnterprise();

   const getStringData = (data: string): string => {
      const result = data.split("/").slice(3).toString();
      return result;
   };

   const openDeviceModal = (device: IDevice): void => {
      setShowDeviceModal(true);
      setActiveDevice(device);
   }

   const closeDeviceModal = (): void => {
      setShowDeviceModal(false);
   }

   useEffect(() => {
      const fetchData = async () => {
         try {
            const result = await axios.get<IDevice[]>(process.env.REACT_APP_HIDR_URL + "/devices/list", {
               params: {
                  parent: enterprise?.name,
               },
               headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
               },
            });
            setDevicesList(result.data);
         } catch (error) {
            if (error && error.response) {
               toast.error(error.response.data.message);
            } else {
               toast.error(errorOccured);
               console.log(error);
            }
         } finally {
            setLoading(false);
         }
      };

      fetchData();
   }, [enterprise?.name]);

   let toRender: JSX.Element;

   if (loading) {
      toRender = <PulseLoader color={"#c702ff"} loading={loading} />;
   } else if (devicesList.length === 0) {
      toRender = <p className={`size-16 novaMedium mb-0`}>There are no devices.</p>;
   } else {
      toRender = (
         <Table striped bordered hover responsive>
            <thead>
               <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Brand</th>
                  <th>Android version</th>
                  <th>Account identifier</th>
                  <th>Policy name</th>
                  <th>Last policy sync time</th>
                  <th>Enrollment token</th>
                  <th>Enrollment time</th>
               </tr>
            </thead>

            <tbody>
               {devicesList.map((device, index) => (
                  <tr key={device.name} onClick={() => openDeviceModal(device)} className="pointer">
                     <td>{index + 1}</td>

                     <td>{device.name ? getStringData(device.name) : "N/A"}</td>

                     <td>{device.hardwareInfo?.brand ? device.hardwareInfo.brand : "N/A"}</td>

                     <td>{device.softwareInfo?.androidVersion ? device.softwareInfo?.androidVersion : "N/A"}</td>

                     <td>{device.user?.accountIdentifier ? device.user?.accountIdentifier : "N/A"}</td>

                     <td>{device.policyName ? getStringData(device.policyName) : "N/A"}</td>

                     <td>
                        <Moment format="YYYY/MM/DD/hh:mm">
                           {device.lastPolicySyncTime ? device.lastPolicySyncTime : "N/A"}
                        </Moment>
                     </td>

                     <td>{device.enrollmentTokenName ? getStringData(device.enrollmentTokenName) : "N/A"}</td>

                     <td>
                        <Moment format="YYYY/MM/DD/hh:mm">
                           {device.enrollmentTime ? device.enrollmentTime : "N/A"}
                        </Moment>
                     </td>
                  </tr>
               ))}
            </tbody>
         </Table>
      );
   }

   return (
      <div className={`pageContentContainer`}>
         <DeviceModal 
            show={showDeviceModal} 
            onHide={closeDeviceModal} 
            deviceItem={activeDevice}
         />
         {toRender}
      </div>
   );
};

export default Devices;
