import React from "react";
import styles from "./ErrorPage.module.scss";
import { ReactComponent as ErrorImage } from "../../Assets/ErrorPage/error.svg";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Utils/routes";

const ErrorPage: React.FC = (): JSX.Element => {
   const navigate = useNavigate();

   return (
      <div className={styles.errorPage}>
         <div className={`mainContainer h-100 d-flex flex-column align-items-center justify-content-center`}>
            <ErrorImage className={styles.errorImage} />
            <h3 className="mb-5 size-30 novaBold700 text-center">Sorry, the page you were looking for was not found</h3>
            <div className="w-100 d-flex justify-content-center">
               <button className={styles.backButton} onClick={() => navigate(routes.enterprises)}>
                  Go back
               </button>
            </div>
         </div>
      </div>
   );
};

export default ErrorPage;
