import React, { useState, useContext, useEffect } from "react";
import { ChildrenProps, IEnterprise } from "../Interfaces/GlobalInterfaces";

const EnterpriseContext = React.createContext<null | IEnterprise>(null);
const SetEnterpriseContext = React.createContext<null | React.Dispatch<React.SetStateAction<null | IEnterprise>>>(null);

export const useEnterprise = () => useContext(EnterpriseContext);
export const useSetEnterprise = () => useContext(SetEnterpriseContext);

const initialState: null | IEnterprise = JSON.parse(localStorage.getItem('enterprise')!)
   ? JSON.parse(localStorage.getItem('enterprise')!)
   : null;

const EnterpriseProvider: React.FC<ChildrenProps> = ({ children }): JSX.Element => {
   const [activeEnterprise, setActiveEnterprise] = useState<null | IEnterprise>(initialState);

   useEffect(() => {
      //Update the localstorage after detected change
      localStorage.setItem("enterprise", JSON.stringify(activeEnterprise));
   }, [activeEnterprise]);

   return (
      <EnterpriseContext.Provider value={activeEnterprise}>
         <SetEnterpriseContext.Provider value={setActiveEnterprise}>{children}</SetEnterpriseContext.Provider>
      </EnterpriseContext.Provider>
   );
};

export default EnterpriseProvider;
