export const hexEncode = (value: string): string => {
   let hex, i;

   let result = "";
   for (i = 0; i < value.length; i++) {
      hex = value.charCodeAt(i).toString(16);
      result += ("000" + hex).slice(-4);
   }

   return result;
};

export const hexDecode = (value: string): string => {
   let j;

   let hexes = value.match(/.{1,4}/g) || [];
   let back = "";
   for (j = 0; j < hexes.length; j++) {
      back += String.fromCharCode(parseInt(hexes[j], 16));
   }

   return back;
};
