import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import styles from "./SettingsModal.module.scss";
import { errorOccured } from "../../Utils/notifications";
import { toast } from "react-toastify";
import axios from "axios";
import { IPolicy } from "../../Interfaces/GlobalInterfaces";

interface Props {
   show: boolean;
   onHide: () => void;
   activePolicy: IPolicy;
   refetchPolicy: () => void;
}

interface IState {
   applicationReportsEnabled: boolean;
   deviceSettingsEnabled: boolean;
   softwareInfoEnabled: boolean;
   memoryInfoEnabled: boolean;
   networkInfoEnabled: boolean;
   displayInfoEnabled: boolean;
   powerManagementEventsEnabled: boolean;
   hardwareStatusEnabled: boolean;
   systemPropertiesEnabled: boolean;
   commonCriteriaModeEnabled: boolean;
   includeRemovedApps: boolean;
}

const initialState = {
   applicationReportsEnabled: false,
   deviceSettingsEnabled: false,
   softwareInfoEnabled: false,
   memoryInfoEnabled: false,
   networkInfoEnabled: false,
   displayInfoEnabled: false,
   powerManagementEventsEnabled: false,
   hardwareStatusEnabled: false,
   systemPropertiesEnabled: false,
   commonCriteriaModeEnabled: false,
   includeRemovedApps: false,
};

const SettingsModal: React.FC<Props> = ({ show, onHide, activePolicy, refetchPolicy }): JSX.Element => {
   const [checkboxesValues, setCheckboxesValues] = useState<IState>(initialState);
   const [disabled, setDisabled] = useState<boolean>(false);

   const checkboxes = [
      {
         label: "Application Reports Enabled",
         name: "applicationReportsEnabled",
      },
      {
         label: "Device Settings Enabled",
         name: "deviceSettingsEnabled",
      },
      {
         label: "Software Info Enabled",
         name: "softwareInfoEnabled",
      },
      {
         label: "Memory Info Enabled",
         name: "memoryInfoEnabled",
      },
      {
         label: "Network Info Enabled",
         name: "networkInfoEnabled",
      },
      {
         label: "Display Info Enabled",
         name: "displayInfoEnabled",
      },
      {
         label: "Power Management Events Enabled",
         name: "powerManagementEventsEnabled",
      },
      {
         label: "Hardware Status Enabled",
         name: "hardwareStatusEnabled",
      },
      {
         label: "System Properties Enabled",
         name: "systemPropertiesEnabled",
      },
      {
         label: "Common Criteria Mode Enabled",
         name: "commonCriteriaModeEnabled",
      },
      {
         label: "Include Removed Apps",
         name: "includeRemovedApps",
      },
   ];

   useEffect(() => {
      if (activePolicy?.statusReportingSettings) {
         let statusReportingSettings = activePolicy.statusReportingSettings;

         let defaultCheckboxesValues = {
            applicationReportsEnabled: statusReportingSettings.applicationReportsEnabled,
            deviceSettingsEnabled: statusReportingSettings.deviceSettingsEnabled,
            softwareInfoEnabled: statusReportingSettings.softwareInfoEnabled,
            memoryInfoEnabled: statusReportingSettings.memoryInfoEnabled,
            networkInfoEnabled: statusReportingSettings.networkInfoEnabled,
            displayInfoEnabled: statusReportingSettings.displayInfoEnabled,
            powerManagementEventsEnabled: statusReportingSettings.powerManagementEventsEnabled,
            hardwareStatusEnabled: statusReportingSettings.hardwareStatusEnabled,
            systemPropertiesEnabled: statusReportingSettings.systemPropertiesEnabled,
            commonCriteriaModeEnabled: statusReportingSettings.commonCriteriaModeEnabled,
            includeRemovedApps: statusReportingSettings.applicationReportingSettings?.includeRemovedApps,
         };

         setCheckboxesValues(defaultCheckboxesValues);
      }
   }, [activePolicy?.statusReportingSettings, show]);

   const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setCheckboxesValues((prevState) => ({
         ...prevState,
         [event.target.name]: event.target.checked,
      }));
   };

   const handlePatchPolicy = async (): Promise<void> => {
      const policy = { ...activePolicy };

      const obj = {
         name: policy?.name,
         requestBody: {
            ...policy,
            statusReportingSettings: {
               applicationReportsEnabled: checkboxesValues.applicationReportsEnabled,
               deviceSettingsEnabled: checkboxesValues.deviceSettingsEnabled,
               softwareInfoEnabled: checkboxesValues.softwareInfoEnabled,
               memoryInfoEnabled: checkboxesValues.memoryInfoEnabled,
               networkInfoEnabled: checkboxesValues.networkInfoEnabled,
               displayInfoEnabled: checkboxesValues.displayInfoEnabled,
               powerManagementEventsEnabled: checkboxesValues.powerManagementEventsEnabled,
               hardwareStatusEnabled: checkboxesValues.hardwareStatusEnabled,
               systemPropertiesEnabled: checkboxesValues.systemPropertiesEnabled,
               ...(checkboxesValues.includeRemovedApps && {
                  applicationReportingSettings: {
                     includeRemovedApps: checkboxesValues.includeRemovedApps,
                  },
               }),
               commonCriteriaModeEnabled: checkboxesValues.commonCriteriaModeEnabled,
            },
         },
      };

      setDisabled(true);
      try {
         const result = await axios.post(process.env.REACT_APP_HIDR_URL + "/policies", obj, {
            headers: {
               Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
         });

         if (result.status === 200 || result.status === 201) {
            toast.success(`Settings saved succesfully. 💯`);
            refetchPolicy();
            setCheckboxesValues(initialState);
            onHide();
         }
      } catch (error) {
         if (error && error.response) {
            toast.error(error.response.data.message);
         } else {
            toast.error(errorOccured);
            console.log(error);
         }
      } finally {
         setDisabled(false);
      }
   };

   const resetValues = (): void => {
      setCheckboxesValues(initialState);
   };

   return (
      <Modal centered show={show} onHide={onHide}>
         <Modal.Header>
            <div className="w-100">
               <div className="d-flex align-items-center justify-content-between w-100 mb-3">
                  <h6>Status Reporting Settings</h6>
                  <button onClick={resetValues} className={`btn btn-sm btn-info ms-3`}>
                     Reset
                  </button>
               </div>
               {checkboxes.map((checkbox, index) => (
                  <div key={index} className="mb-2">
                     <input
                        className="me-3"
                        name={checkbox.name}
                        id={checkbox.name}
                        type="checkbox"
                        checked={checkboxesValues[checkbox.name]}
                        onChange={(event) => handleChecked(event)}
                     />
                     <label htmlFor={checkbox.name}>{checkbox.label}</label>
                  </div>
               ))}

               <button
                  disabled={disabled}
                  onClick={handlePatchPolicy}
                  className={`${styles.mainActionButton} mt-4 d-block`}
               >
                  Save Settings
               </button>
            </div>
         </Modal.Header>
      </Modal>
   );
};

export default SettingsModal;
