import React, { useState } from "react";
import { IPolicy } from "../../../../../Interfaces/GlobalInterfaces";
import { Table, Button } from "react-bootstrap";
import EditAppsModal from "../../../../../Components/EditAppsModal/EditAppsModal";
import DeleteApp from "../../../../../Components/DeleteApp/DeleteApp";
import { errorOccured } from "../../../../../Utils/notifications";
import { toast } from "react-toastify";
import axios from "axios";

interface IProps {
   policy: IPolicy;
   refetchPolicy: () => void;
}

interface IApplication {
   installType?: string;
   packageName?: string;
}

const Applications: React.FC<IProps> = ({ policy, refetchPolicy }): JSX.Element => {
   const [activeApplication, setActiveApplication] = useState<IApplication>({});
   const [showEditModal, setShowEditModal] = useState<boolean>(false);
   const [showDeleteApp, setShowDeleteApp] = useState<boolean>(false);

   const handleChecked = (event: React.ChangeEvent<HTMLInputElement>, application: IApplication): void => {
      if (event.target.checked) {
         setActiveApplication(application);
      } else {
         setActiveApplication({});
      }
   };

   // edit app
   const handleOpenEditModal = (): void => {
      setShowEditModal(true);
   };

   const handleCloseEditModal = async (): Promise<void> => {
      setShowEditModal(false);
      await refetchPolicy();
   };

   // delete app
   const handleOpenDeleteModal = (): void => {
      setShowDeleteApp(true);
   };

   const handleCloseDeleteModal = async (): Promise<void> => {
      setShowDeleteApp(false);
   };

   const handleDeleteApplication = async (): Promise<void> => {
      let applications = policy.applications;

      if (applications.length > 0) {
         applications = applications.filter((app) => app.packageName !== activeApplication.packageName);
      }

      const obj = {
         name: policy.name,
         requestBody: {
            ...policy,
            applications,
         },
      };

      try {
         const result = await axios.post(process.env.REACT_APP_HIDR_URL + "/policies", obj, {
            headers: {
               Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
         });
         if (result.status === 200 || result.status === 201) {
            toast.success("You have succesfully deleted the application");
            handleCloseDeleteModal();
            await refetchPolicy();
         }
      } catch (error) {
         if (error && error.response) {
            toast.error(error.response.data.message);
         } else {
            toast.error(errorOccured);
            console.log(error);
         }
      }
   };

   let toRender: JSX.Element;

   if (!policy.applications) {
      toRender = <p>No applications.</p>;
   } else if (policy?.applications.length === 0) {
      toRender = <p>No applications.</p>;
   } else {
      toRender = (
         <div>
            <div className={`mb-3 d-flex gap-2`}>
               <Button
                  type="button"
                  disabled={policy?.applications?.length === 0}
                  className={`btn btn-sm btn-success`}
                  onClick={handleOpenEditModal}
               >
                  Edit / Add
               </Button>
               <Button
                  disabled={Object.keys(activeApplication).length === 0}
                  type="button"
                  className={`btn btn-sm ${
                     Object.keys(activeApplication).length === 0 ? "btn-secondary" : "btn-danger"
                  }`}
                  onClick={handleOpenDeleteModal}
               >
                  Delete
               </Button>
            </div>
            <Table striped bordered hover responsive>
               <thead>
                  <tr>
                     <th>#</th>
                     <th>Install Type</th>
                     <th>Package Name</th>
                  </tr>
               </thead>

               <tbody>
                  {policy.applications.map((app, index) => (
                     <tr key={index} className="pointer">
                        <td>
                           <input
                              name="checked"
                              type="checkbox"
                              checked={
                                 activeApplication &&
                                 Object.keys(activeApplication).length !== 0 &&
                                 activeApplication.packageName === app.packageName
                              }
                              onChange={(event) => handleChecked(event, app)}
                           />
                        </td>
                        <td>{app.installType}</td>
                        <td>{app.packageName}</td>
                     </tr>
                  ))}
               </tbody>
            </Table>
         </div>
      );
   }

   return (
      <div>
         {toRender}
         <EditAppsModal show={showEditModal} onHide={handleCloseEditModal} policy={policy} />
         <DeleteApp
            show={showDeleteApp}
            onHide={handleCloseDeleteModal}
            handleDeleteApplication={handleDeleteApplication}
            appItem={activeApplication}
         />
      </div>
   );
};

export default Applications;
