import React, { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { errorOccured } from "../../../Utils/notifications";
import PulseLoader from "react-spinners/PulseLoader";
import { Table } from "react-bootstrap";
import { IPolicy } from "../../../Interfaces/GlobalInterfaces";
import { useEnterprise } from "../../../Context/EnterpriseProivder";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { hexEncode } from "../../../Utils/hex";
import PolicyModal from "../../../Components/PolicyModal/PolicyModal";
import DeletePolicyModal from "../../../Components/DeletePolicy/DeletePolicy";

const ListPolicies: React.FC = (): JSX.Element => {
   const [policiesList, setPoliciesList] = useState<IPolicy[]>([]);
   const [loading, setLoading] = useState<boolean>(true);
   const [showPolicyModal, setShowPolicyModal] = useState<boolean>(false);
   const [showDeletePolicyModal, setShowDeletePolicyModal] = useState<boolean>(false);
   const [activePolicy, setActivePolicy] = useState<IPolicy>({});

   const enterprise = useEnterprise();
   const navigate = useNavigate();

   const getStringData = (data: string): string => {
      const result = data.split("/").slice(3).toString();
      return result;
   };

   // json policy modal
   const openPolicyModal = (): void => {
      setShowPolicyModal(true);
   };

   const closePolicyModal = (): void => {
      setShowPolicyModal(false);
   };

   // delete policy modal
   const openDeletePolicyModal = (): void => {
      setShowDeletePolicyModal(true);
   };

   const closeDeletePolicyModal = (): void => {
      setShowDeletePolicyModal(false);
   };

   // edit policy by checking
   const handleEditPolicy = (): void => {
      let policyItem = activePolicy.name!;
      navigate(routes.policy + "/" + hexEncode(policyItem));
   };

   const handleChecked = (event: React.ChangeEvent<HTMLInputElement>, policy: IPolicy): void => {
      if (event.target.checked) {
         setActivePolicy(policy);
      } else {
         setActivePolicy({});
      }
   };

   // edit policy by clicking on the row
   const handleEditPolicyRow = (policy: IPolicy): void => {
      let policyItem = policy.name!;
      navigate(routes.policy + "/" + hexEncode(policyItem));
   };

   // delete policy
   const handleDeletePolicy = async (): Promise<void> => {
      try {
         const result = await axios.delete(process.env.REACT_APP_HIDR_URL + "/policies", {
            headers: {
               Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
            data: {
               name: `${activePolicy?.name}`,
            },
         });

         if (result.status === 200) {
            toast.success("You have succesfully deleted the policy");
            closeDeletePolicyModal();
            fetchPolicies();
         }
      } catch (error) {
         if (error && error.response) {
            toast.error(error.response.data.message);
         } else {
            toast.error(errorOccured);
            console.log(error);
         }
      }
   };

   const fetchPolicies = async (): Promise<void> => {
      try {
         const result = await axios.get<IPolicy[]>(process.env.REACT_APP_HIDR_URL + "/policies/list", {
            params: {
               parent: enterprise?.name,
            },
            headers: {
               Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
         });
         setPoliciesList(result.data);
      } catch (error) {
         if (error && error.response) {
            toast.error(error.response.data.message);
         } else {
            toast.error(errorOccured);
            console.log(error);
         }
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchPolicies();
   }, []);

   let toRender: JSX.Element;

   if (loading) {
      toRender = <PulseLoader color={"#c702ff"} loading={loading} />;
   } else if (policiesList.length === 0) {
      toRender = <p className={`size-16 novaMedium mb-0`}>There are no policies.</p>;
   } else {
      toRender = (
         <Table striped bordered hover responsive>
            <thead>
               <tr>
                  <th>#</th>
                  <th>Name</th>
                  <th>Version</th>
                  <th>Debugging features allowed</th>
                  <th>Applications number</th>
               </tr>
            </thead>

            <tbody>
               {policiesList.map((policy) => (
                  <tr onClick={() => handleEditPolicyRow(policy)} key={policy.name} className="pointer">
                     <td onClick={(event) => event.stopPropagation()}>
                        <input
                           name="checked"
                           type="checkbox"
                           checked={
                              activePolicy &&
                              Object.keys(activePolicy).length !== 0 &&
                              activePolicy.name === policy.name
                           }
                           onChange={(event) => handleChecked(event, policy)}
                        />
                     </td>

                     <td>{policy.name ? getStringData(policy.name) : "N/A"}</td>

                     <td>{policy.version ? policy.version : "N/A"}</td>

                     <td>{policy.debuggingFeaturesAllowed ? policy.debuggingFeaturesAllowed.toString() : "N/A"}</td>

                     <td>{policy.applications ? policy.applications.length : "N/A"}</td>
                  </tr>
               ))}
            </tbody>
         </Table>
      );
   }

   return (
      <div className={`mt-3`}>
         <div className={`mb-3 d-flex gap-2`}>
            <Button
               type="button"
               disabled={Object.keys(activePolicy).length === 0}
               className={`btn btn-sm ${Object.keys(activePolicy).length === 0 ? "btn-secondary" : "btn-success"}`}
               onClick={handleEditPolicy}
            >
               Edit
            </Button>
            <Button
               disabled={Object.keys(activePolicy).length === 0}
               type="button"
               className={`btn btn-sm ${Object.keys(activePolicy).length === 0 ? "btn-secondary" : "btn-danger"}`}
               onClick={openDeletePolicyModal}
            >
               Delete
            </Button>
            <Button
               disabled={Object.keys(activePolicy).length === 0}
               type="button"
               className={`btn btn-sm ${Object.keys(activePolicy).length === 0 ? "btn-secondary" : "btn-warning"}`}
               onClick={openPolicyModal}
            >
               See JSON
            </Button>
         </div>
         <PolicyModal show={showPolicyModal} onHide={closePolicyModal} policyItem={activePolicy} />
         <DeletePolicyModal
            show={showDeletePolicyModal}
            onHide={closeDeletePolicyModal}
            policyItem={activePolicy}
            handleDeletePolicy={handleDeletePolicy}
         />
         {toRender}
      </div>
   );
};

export default ListPolicies;
