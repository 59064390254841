import React, { useState, useEffect } from "react";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { errorOccured } from "../../../../Utils/notifications";
import { useEnterprise } from "../../../../Context/EnterpriseProivder";
import { toast } from "react-toastify";
import styles from "../Policies.module.scss";

type IProps = {
   handleNextStep: () => void;
   name: string;
};

const SecondStep: React.FC<IProps> = ({ handleNextStep, name }) => {
   const [token, setToken] = useState<string>("");
   const [loading, setLoading] = useState<boolean>(true);

   const enterprise = useEnterprise();

   useEffect(() => {
      const fetchData = async (): Promise<void> => {
         try {
            const result = await axios.post(
               process.env.REACT_APP_HIDR_URL + "/web-token",
               {
                  parent: enterprise?.name,
                  requestBody: {
                     parentFrameUrl: window.location.origin,
                  },
               },
               {
                  headers: {
                     Authorization: "Bearer " + localStorage.getItem("jwt"),
                  },
               }
            );
            setToken(result.data.data.value);
         } catch (error) {
            if (error && error.response) {
               toast.error(error.response.data.message);
            } else {
               toast.error(errorOccured);
               console.log(error);
            }
         } finally {
            setLoading(false);
         }
      };

      fetchData();
   }, [enterprise?.name]);

   let toRender: JSX.Element;

   if (loading) {
      toRender = <PulseLoader color={"#c702ff"} loading={loading} />;
   } else if (!token) {
      toRender = <p className={`size-16 novaMedium mb-0`}>Wrong token.</p>;
   } else {
      toRender = (
         <iframe title="myFrame" style={{ height: "600px" }} className={`w-100`} src={`/iframe.html?token=${token}&name=${enterprise?.name}/policies/${name}`} />
      );
   }

   return (
      <React.Fragment>
         {toRender}
         <button onClick={handleNextStep} className={`${styles.mainActionButton} mt-4`}>
            Next
         </button>
      </React.Fragment>
   );
};

export default SecondStep;
