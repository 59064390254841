import React, { useState } from "react";
import styles from "./Policies.module.scss";
import SwitchSelector from "react-switch-selector";
import ListPolicies from "./ListPolicies";
import GeneratePolicy from "./GeneratePolicy";

interface IOption {
   label: string;
   value: string;
   selectedBackgroundColor: string;
   selectedFontColor: string;
   fontColor?: string;
}

const Policies: React.FC = (): JSX.Element => {
   const [displayType, setDisplayType] = useState<string>("list");

   const options: IOption[] = [
      {
         label: "List Policies",
         value: "list",
         selectedBackgroundColor: "#6c008a",
         selectedFontColor: "#FFFFFF",
         fontColor: "#FFFFFF",
      },
      {
         label: "Generate Policy",
         value: "generate",
         selectedBackgroundColor: "#6c008a",
         selectedFontColor: "#FFFFFF",
      },
   ];

   const handleSwitch = (value: any): void => {
      setDisplayType(value);
   };

   let toRender: JSX.Element;

   if (displayType === "list") {
      toRender = <ListPolicies />;
   } else {
      toRender = <GeneratePolicy />;
   }

   return (
      <div className={`pageContentContainer`}>
         <div className={styles.switchSelectorWrapper}>
            <SwitchSelector
               onChange={handleSwitch}
               options={options}
               initialSelectedIndex={0}
               backgroundColor={"#333333"}
               fontColor={"#FFFFFF"}
            />
         </div>
         {toRender}
      </div>
   );
};

export default Policies;
