import React, { useState } from "react";
import axios from "axios";
import { errorOccured } from "../../../../Utils/notifications";
import { useEnterprise } from "../../../../Context/EnterpriseProivder";
import { toast } from "react-toastify";
import styles from "../Policies.module.scss";
import { IPolicy } from "../../../../Interfaces/GlobalInterfaces";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../../Utils/routes";
import { hexEncode } from "../../../../Utils/hex";

type IProps = {
   handleNextStep: () => void;
   name: string;
};

interface IState {
   applicationReportsEnabled: boolean;
   deviceSettingsEnabled: boolean;
   softwareInfoEnabled: boolean;
   memoryInfoEnabled: boolean;
   networkInfoEnabled: boolean;
   displayInfoEnabled: boolean;
   powerManagementEventsEnabled: boolean;
   hardwareStatusEnabled: boolean;
   systemPropertiesEnabled: boolean;
   commonCriteriaModeEnabled: boolean;
   includeRemovedApps: boolean;
}

const initialState = {
   applicationReportsEnabled: false,
   deviceSettingsEnabled: false,
   softwareInfoEnabled: false,
   memoryInfoEnabled: false,
   networkInfoEnabled: false,
   displayInfoEnabled: false,
   powerManagementEventsEnabled: false,
   hardwareStatusEnabled: false,
   systemPropertiesEnabled: false,
   commonCriteriaModeEnabled: false,
   includeRemovedApps: false,
};

const ThirdStep: React.FC<IProps> = ({ handleNextStep, name }): JSX.Element => {
   const [checkboxesValues, setCheckboxesValues] = useState<IState>(initialState);
   const [disabled, setDisabled] = useState<boolean>(false);

   const enterprise = useEnterprise();
   const navigate = useNavigate();

   const checkboxes = [
      {
         label: "Application Reports Enabled",
         name: "applicationReportsEnabled",
      },
      {
         label: "Device Settings Enabled",
         name: "deviceSettingsEnabled",
      },
      {
         label: "Software Info Enabled",
         name: "softwareInfoEnabled",
      },
      {
         label: "Memory Info Enabled",
         name: "memoryInfoEnabled",
      },
      {
         label: "Network Info Enabled",
         name: "networkInfoEnabled",
      },
      {
         label: "Display Info Enabled",
         name: "displayInfoEnabled",
      },
      {
         label: "Power Management Events Enabled",
         name: "powerManagementEventsEnabled",
      },
      {
         label: "Hardware Status Enabled",
         name: "hardwareStatusEnabled",
      },
      {
         label: "System Properties Enabled",
         name: "systemPropertiesEnabled",
      },
      {
         label: "Common Criteria Mode Enabled",
         name: "commonCriteriaModeEnabled",
      },
      {
         label: "Include Removed Apps",
         name: "includeRemovedApps",
      },
   ];

   const handleChecked = (event: React.ChangeEvent<HTMLInputElement>): void => {
      setCheckboxesValues((prevState) => ({
         ...prevState,
         [event.target.name]: event.target.checked,
      }));
   };

   const handlePatchPolicy = async (): Promise<void> => {
      const fetchData = async () => {
         try {
            const result = await axios.get<IPolicy>(process.env.REACT_APP_HIDR_URL + `/policies/`, {
               params: {
                  name: `${enterprise?.name}/policies/${name}`,
               },
               headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
               },
            });

            if (result.status === 200) {
               return result.data;
            }
         } catch (error) {
            if (error && error.response) {
               toast.error(error.response.data.message);
            } else {
               toast.error(errorOccured);
               console.log(error);
            }
         }
      };

      const policy = await fetchData();

      const obj = {
         name: policy?.name,
         requestBody: {
            ...policy,
            statusReportingSettings: {
               applicationReportsEnabled: checkboxesValues.applicationReportsEnabled,
               deviceSettingsEnabled: checkboxesValues.deviceSettingsEnabled,
               softwareInfoEnabled: checkboxesValues.softwareInfoEnabled,
               memoryInfoEnabled: checkboxesValues.memoryInfoEnabled,
               networkInfoEnabled: checkboxesValues.networkInfoEnabled,
               displayInfoEnabled: checkboxesValues.displayInfoEnabled,
               powerManagementEventsEnabled: checkboxesValues.powerManagementEventsEnabled,
               hardwareStatusEnabled: checkboxesValues.hardwareStatusEnabled,
               systemPropertiesEnabled: checkboxesValues.systemPropertiesEnabled,
               applicationReportingSettings: {
                  includeRemovedApps: checkboxesValues.includeRemovedApps,
               },
               commonCriteriaModeEnabled: checkboxesValues.commonCriteriaModeEnabled,
            },
         },
      };

      setDisabled(true);
      try {
         const result = await axios.post(process.env.REACT_APP_HIDR_URL + "/policies", obj, {
            headers: {
               Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
         });

         if (result.status === 200 || result.status === 201) {
            toast.success(`Settings added succesfully. Redirecting to policy page 💥`);
            navigate(routes.policy + "/" + hexEncode(result.data.name));
         }
      } catch (error) {
         if (error && error.response) {
            toast.error(error.response.data.message);
         } else {
            toast.error(errorOccured);
            console.log(error);
         }
      } finally {
         setDisabled(false);
      }
   };

   return (
      <div>
         <h6 className="mb-3">Status Reporting Settings</h6>
         {checkboxes.map((checkbox, index) => (
            <div key={index} className="mb-2">
               <input
                  className="me-3"
                  name={checkbox.name}
                  id={checkbox.name}
                  type="checkbox"
                  checked={checkboxesValues[checkbox.name]}
                  onChange={(event) => handleChecked(event)}
               />
               <label htmlFor={checkbox.name}>{checkbox.label}</label>
            </div>
         ))}

         <button disabled={disabled} onClick={handlePatchPolicy} className={`${styles.mainActionButton} mt-4 d-block`}>
            Add Settings
         </button>
      </div>
   );
};

export default ThirdStep;
