import React, { useState, useEffect } from "react";
import styles from "./EnrollmentToken.module.scss";
import { IPolicy } from "../../../Interfaces/GlobalInterfaces";
import PulseLoader from "react-spinners/PulseLoader";
import axios from "axios";
import { toast } from "react-toastify";
import { errorOccured } from "../../../Utils/notifications";
import Fade from "react-reveal/Fade";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaCopy as CopyIcon } from "react-icons/fa";
import { useEnterprise } from "../../../Context/EnterpriseProivder";

interface InputValues {
   additionalData: string;
   allowPersonalUsage: string;
   durationType: number;
   oneTimeOnly: boolean;
   accountIdentifier: string;
}

interface SelectValues {
   allowPersonalUsage: string;
   policyName?: string;
}

const initialInputValues: InputValues = {
   additionalData: "",
   allowPersonalUsage: "",
   durationType: 0,
   oneTimeOnly: false,
   accountIdentifier: "",
};

const initialSelectValues: SelectValues = {
   allowPersonalUsage: "ALLOW_PERSONAL_USAGE_UNSPECIFIED",
   policyName: "",
};

const GenerateEnrollmentToken: React.FC = (): JSX.Element => {
   const [step, setStep] = useState<number>(0);
   const [token, setToken] = useState<string>("");
   const [values, setValues] = useState<InputValues>(initialInputValues);
   const [selectValues, setSelectValues] = useState<SelectValues>(initialSelectValues);
   const [policiesList, setPoliciesList] = useState<IPolicy[]>([]);
   const [loading, setLoading] = useState<boolean>(true);

   const enterprise = useEnterprise();

   const handleInputChange = (e: React.FormEvent<HTMLInputElement>, inputType?: string) => {
      const target = e.currentTarget;
      const { name, value, checked } = target;

      if (inputType === "checkbox") {
         setValues({ ...values, [name]: checked });
      } else {
         setValues({ ...values, [name]: value });
      }
   };

   const handleCopy = (event): void => {
      event.preventDefault();
      toast.success("Token copied successfully");
      setStep(0);
   };

   const handleSelectChange = (e: React.FormEvent<HTMLSelectElement>) => {
      const target = e.currentTarget;
      const { value, name } = target;

      setSelectValues({ ...selectValues, [name]: value });
   };

   const handleGenerateToken = async (event): Promise<void> => {
      event.preventDefault();

      if (!values.additionalData || !values.durationType || !values.accountIdentifier) {
         toast.error("All fields are mandatory");
      } else {
         try {
            const result = await axios.post(
               process.env.REACT_APP_HIDR_URL + "/enrollment",
               {
                  requestBody: {
                     additionalData: values.additionalData,
                     allowPersonalUsage: selectValues.allowPersonalUsage,
                     duration: values.durationType + "s",
                     oneTimeOnly: values.oneTimeOnly,
                     policyName: selectValues.policyName,
                     user: {
                        accountIdentifier: values.accountIdentifier,
                     },
                  },
                  parent: enterprise?.name,
               },
               {
                  headers: {
                     Authorization: "Bearer " + localStorage.getItem("jwt"),
                  },
               }
            );

            if (result.status === 200 || result.status === 201) {
               setToken(result.data.value);
               toast.success("You have created a new enrollment token succesfully");
               setStep(1);
            }
         } catch (error) {
            if (error && error.response) {
               toast.error(error.response.data.message);
            } else {
               toast.error(errorOccured);
               console.log(error);
            }
         }
      }
   };

   useEffect(() => {
      setStep(0);

      const fetchData = async (): Promise<void> => {
         try {
            const result = await axios.get<IPolicy[]>(process.env.REACT_APP_HIDR_URL + "/policies/list", {
               params: {
                  parent: enterprise?.name,
               },
               headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
               },
            });
            setPoliciesList(result.data);
            setSelectValues((prevState) => ({
               ...prevState,
               policyName: result.data[0]?.name,
            }));
         } catch (error) {
            if (error && error.response) {
               toast.error(error.response.data.message);
            } else {
               toast.error(errorOccured);
               console.log(error);
            }
         } finally {
            setLoading(false);
         }
      };

      fetchData();
   }, [enterprise?.name]);

   let toRender: JSX.Element;

   if (loading) {
      toRender = <PulseLoader color={"#c702ff"} loading={loading} />;
   } else if (policiesList.length === 0) {
      toRender = <p className={`size-16 novaMedium mb-0`}>There are no policies.</p>;
   } else {
      toRender = (
         <form className={styles.tokenForm}>
            {step === 0 ? (
               <>
                  <h4 className="mb-5 size-30 novaBold700">Generate Enrollment Token</h4>

                  <label className={`mb-4 ${styles.inputLabel}`}>
                     <span className="mb-2 size-18 novaMedium">Additional Data</span>
                     <input
                        name="additionalData"
                        type="text"
                        className={styles.tokenInputs}
                        onChange={(e) => handleInputChange(e)}
                     />
                  </label>

                  <label className={`mb-4 ${styles.inputLabel}`}>
                     <span className="mb-2 size-18 novaMedium">Allow Personal Usage </span>
                     <select
                        placeholder="Allow personal usage"
                        name="allowPersonalUsage"
                        className={`mt-2 pointer ${styles.tokenInputs}`}
                        onChange={(e) => handleSelectChange(e)}
                     >
                        <option value={"ALLOW_PERSONAL_USAGE_UNSPECIFIED"}>ALLOW_PERSONAL_USAGE_UNSPECIFIED</option>
                        <option value={"PERSONAL_USAGE_ALLOWED"}>PERSONAL_USAGE_ALLOWED</option>
                        <option value={"PERSONAL_USAGE_DISALLOWED"}>PERSONAL_USAGE_DISALLOWED</option>
                     </select>
                  </label>

                  <label className={`mb-4 ${styles.inputLabel}`}>
                     <span className="mb-2 size-18 novaMedium">Duration Type (s)</span>
                     <input
                        name="durationType"
                        type="number"
                        className={styles.tokenInputs}
                        onChange={(e) => handleInputChange(e)}
                     />
                  </label>

                  <label className="mb-4 d-flex align-items-center">
                     <span className="me-2 size-18 novaMedium pointer">One Time Only</span>
                     <input
                        name="oneTimeOnly"
                        type="checkbox"
                        onChange={(e) => handleInputChange(e, "checkbox")}
                     />
                  </label>
                  <label className={`mb-4`}>
                     <span className="mb-2 size-18 novaMedium">Policy Name</span>
                     <select
                        placeholder="Policy name"
                        name="policyName"
                        className={`mt-2 pointer ${styles.tokenInputs}`}
                        onChange={(e) => handleSelectChange(e)}
                     >
                        {policiesList.map((policy, index) => (
                           <option key={index} value={policy.name}>
                              {policy.name}
                           </option>
                        ))}
                     </select>
                  </label>

                  <label className={`${styles.inputLabel}`}>
                     <span className="mb-2 size-18 novaMedium">Account Identifier </span>
                     <input
                        name="accountIdentifier"
                        type="text"
                        className={styles.tokenInputs}
                        onChange={(e) => handleInputChange(e)}
                     />
                  </label>

                  <button onClick={handleGenerateToken} className={`${styles.mainActionButton} mt-4`}>
                     Create new policy
                  </button>
               </>
            ) : (
               <Fade>
                  <p>Token: {token}</p>
                  <div className="mt-4 w-100 d-flex justify-content-end">
                     <CopyToClipboard text={token} onCopy={handleCopy}>
                        <button
                           className={`d-flex align-items-center justify-content-center pointer ${styles.copyButton}`}
                        >
                           <CopyIcon className="me-2" />
                           <span>Copy</span>
                        </button>
                     </CopyToClipboard>
                  </div>
               </Fade>
            )}
         </form>
      );
   }

   return <div className={`pageContentContainer ${styles.tokenPage}`}>{toRender}</div>;
};

export default GenerateEnrollmentToken;
