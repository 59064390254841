import React, { useState } from "react";
import styles from "./Policies.module.scss";
import FirstStep from "./GenerateSteps/FirstStep";
import SecondStep from "./GenerateSteps/SecondStep";
import ThirdStep from "./GenerateSteps/ThirdStep";

const GeneratePolicy: React.FC = (): JSX.Element => {
   const [name, setName] = useState<string>("");
   const [step, setStep] = useState<number>(0);
   const lastStep = 2;

   const handleNextStep = (): void => {
      setStep(step < lastStep ? step + 1 : step);
   };

   let toRender: JSX.Element;

   if (step === 0) {
      toRender = <FirstStep name={name} setName={setName} handleNextStep={handleNextStep} />;
   } else if (step === 1) {
      toRender = <SecondStep name={name} handleNextStep={handleNextStep} />;
   } else if (step === 2) {
      toRender = <ThirdStep name={name} handleNextStep={handleNextStep} />;
   }

   return (
      <div className={`my-3`}>
         <div className={styles.policyForm}>{toRender!}</div>
      </div>
   );
};

export default GeneratePolicy;
