import React, { useState } from "react";
import { Outlet } from "react-router-dom";

import Header from "../Components/Header/Header";
import Sidebar from "../Components/Sidebar/Sidebar";

const AdminLayout: React.FC = (): JSX.Element => {
   const [showMenu, setShowMenu] = useState<boolean>(() => false);

   const toggleMenu = (): void => setShowMenu(!showMenu);

   return (
      // layout for logged in users
      <div className="position-relative layout">
         <Header toggleMenu={toggleMenu} />
         <Sidebar showMenu={showMenu} toggleMenu={toggleMenu} />

         <div>
            {/* main content */}
            <Outlet />
         </div>
      </div>
   );
};

export default AdminLayout;
