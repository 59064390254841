import React, { useState, useRef } from "react";
import styles from "./Onboarding.module.scss";
import { useNavigate } from "react-router-dom";
import { routes } from "../../Utils/routes";
import { AiFillEye as RevealPwdIcon, AiFillEyeInvisible as HidePwdIcon } from "react-icons/ai";
import { namePattern, passwordPattern } from "../../Utils/regexPatterns";
import { toast } from "react-toastify";
import {
   emptyInputsError,
   invalidUsername,
   passwordNotValid,
   loginSuccess,
   errorOccured,
} from "../../Utils/notifications";
import axios from "axios";

interface IToken {
   access_token: string;
}

const SignIn: React.FC = (): JSX.Element => {
   const [hidePassword, setHidePassword] = useState<boolean>(() => true);

   const usernameRef = useRef<HTMLInputElement>(null);
   const passwordRef = useRef<HTMLInputElement>(null);

   const navigate = useNavigate();

   const handleToggleHidePassword = (): void => setHidePassword(!hidePassword);

   const handleSignIn = async (e: React.FormEvent): Promise<void> => {
      e.preventDefault();

      // input values
      const usernameInputValue = usernameRef.current!.value;
      const passwordInputValue = passwordRef.current!.value;

      // validate values
      const validUsername = namePattern.test(usernameInputValue);
      const validPassword = passwordPattern.test(passwordInputValue);

      if (usernameInputValue === "" || passwordInputValue === "") {
         toast.error(emptyInputsError);
      } else if (!validUsername) {
         toast.error(invalidUsername);
      } else if (!validPassword) {
         toast.error(passwordNotValid);
      } else {
         try {
            const result = await axios.post<IToken>(process.env.REACT_APP_HIDR_URL + "/auth/login", {
               username: usernameInputValue,
               password: passwordInputValue,
            });
            localStorage.setItem("jwt", result.data.access_token);
            navigate(routes.enterprises);
            toast.success(loginSuccess);
         } catch (error) {
            if (error && error.response) {
               toast.error(error.response.data.message);
            } else {
               toast.error(errorOccured);
               console.log(error);
            }
         }
      }
   };

   return (
      <div className={`d-flex justify-content-center align-items-center ${styles.onboarding}`}>
         <div className={`mainContainer d-flex flex-column justify-content-center align-items-center`}>
            <form className={`d-flex flex-column ${styles.formContainer}`} onSubmit={(e) => handleSignIn(e)}>
               <h2 className={`mb-5 text-center nova-bold size-40 novaBold900 textMagentaGradient`}>Sign in</h2>

               {process.env.NODE_ENV === "development" ? (
                  <>
                     <label className={styles.inputLabel}>
                        <span className={`size-20 novaBold700`}>Username</span>
                        <input
                           ref={usernameRef}
                           type="text"
                           className={`mt-2 ${styles.loginInput}`}
                           placeholder="Enter username"
                           autoComplete="off"
                           value="robert@gmail.com"
                           onChange={() => {}}
                        />
                     </label>

                     <label className={styles.inputLabel}>
                        <span className={`size-20 novaBold700`}>Password</span>
                        <div className={`mt-2 ${styles.inputContainer}`}>
                           <input
                              ref={passwordRef}
                              type={hidePassword ? "password" : "text"}
                              className={styles.loginInput}
                              placeholder="Enter password"
                              autoComplete="off"
                              value="Parola123@"
                              onChange={() => {}}
                           />

                           {hidePassword ? (
                              <RevealPwdIcon className={styles.inputIcons} onClick={handleToggleHidePassword} />
                           ) : (
                              <HidePwdIcon className={styles.inputIcons} onClick={handleToggleHidePassword} />
                           )}
                        </div>
                     </label>
                  </>
               ) : (
                  <>
                     <label className={styles.inputLabel}>
                        <span className={`size-20 novaBold700`}>Username</span>
                        <input
                           ref={usernameRef}
                           type="text"
                           className={`mt-2 ${styles.loginInput}`}
                           placeholder="Enter username"
                           autoComplete="off"
                           // value="robert@gmail.com"
                           // onChange={() => {}}
                        />
                     </label>

                     <label className={styles.inputLabel}>
                        <span className={`size-20 novaBold700`}>Password</span>
                        <div className={`mt-2 ${styles.inputContainer}`}>
                           <input
                              ref={passwordRef}
                              type={hidePassword ? "password" : "text"}
                              className={styles.loginInput}
                              placeholder="Enter password"
                              autoComplete="off"
                              // value="Parola123@"
                              // onChange={() => {}}
                           />

                           {hidePassword ? (
                              <RevealPwdIcon className={styles.inputIcons} onClick={handleToggleHidePassword} />
                           ) : (
                              <HidePwdIcon className={styles.inputIcons} onClick={handleToggleHidePassword} />
                           )}
                        </div>
                     </label>
                  </>
               )}

               <button className={styles.mainActionButton} onClick={handleSignIn}>
                  Sign in
               </button>

               <div className="mt-3 w-100 d-flex justify-content-center">
                  <span
                     className={`size-16 weight-500 pointer highlightSpan`}
                     onClick={() => navigate(routes.forgotPassword)}
                  >
                     Forgot password
                  </span>
               </div>
            </form>
         </div>
      </div>
   );
};

export default SignIn;
