import React, { useState } from "react";
import { IPolicy } from "../../../../../Interfaces/GlobalInterfaces";
import { IoMdClose as CloseIcon, IoMdCheckmarkCircleOutline as CheckIcon } from "react-icons/io";
import styles from "../PolicyItem.module.scss";
import { Button } from "react-bootstrap";
import SettingsModal from "../../../../../Components/SettingsModal/SettingsModal";

interface IProps {
   policy: IPolicy;
   refetchPolicy: () => void;
}

const Settings: React.FC<IProps> = ({ policy, refetchPolicy }): JSX.Element => {
   const [showEditModal, setShowEditModal] = useState<boolean>(false);

   const handleOpenEditModal = (): void => setShowEditModal(true);
   const handleCloseEditModal = (): void => setShowEditModal(false);

   return (
      <div>
         <Button type="button" className={`btn btn-sm btn-success mb-3`} onClick={handleOpenEditModal}>
            Edit / Add
         </Button>
         {policy?.statusReportingSettings && Object.keys(policy?.statusReportingSettings).length > 0 ? (
            Object.keys(policy.statusReportingSettings).map((setting, index) => (
               <p className="text-break" key={index}>
                  <strong>{setting}:</strong>
                  <span className="ms-2">
                     {policy.statusReportingSettings[setting] ? (
                        <CheckIcon className={`${styles.settingIcon} ${styles.checked}`} />
                     ) : (
                        <CloseIcon className={`${styles.settingIcon} ${styles.close}`} />
                     )}
                  </span>
               </p>
            ))
         ) : (
            <p>No settings.</p>
         )}
         <SettingsModal show={showEditModal} onHide={handleCloseEditModal} activePolicy={policy} refetchPolicy={refetchPolicy} />
      </div>
   );
};

export default Settings;
