import React from "react";
import { Modal } from "react-bootstrap";
import { IPolicy } from "../../Interfaces/GlobalInterfaces";
import { Button } from "react-bootstrap";

interface Props {
   show: boolean;
   onHide: () => void;
   handleDeletePolicy: () => Promise<void>;
   policyItem: IPolicy;
}

const DeletePolicyModal: React.FC<Props> = ({ show, onHide, handleDeletePolicy, policyItem }): JSX.Element => {
   return (
      <Modal centered show={show} onHide={onHide}>
         <Modal.Header>
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
               <p className="size-20 text-center fw-bold">
                  Are you sure you want to delete <span className="text-danger">{policyItem.name?.split("/")[3]}</span>{" "}
                  policy?
               </p>
            </div>
         </Modal.Header>
         <Modal.Body>
            <div className="w-100 d-flex justify-content-center align-items-center gap-2 fflex-wrap">
               <Button className="btn btn-danger" onClick={handleDeletePolicy}>
                  Delete
               </Button>
               <Button className="btn btn-secondary" onClick={onHide}>
                  Close
               </Button>
            </div>
         </Modal.Body>
      </Modal>
   );
};

export default DeletePolicyModal;
