import React from "react";
import styles from "./DeviceModal.module.scss";
import { Modal } from "react-bootstrap";
import { IDevice } from "../../Interfaces/GlobalInterfaces";
import { FaCopy as CopyIcon } from "react-icons/fa";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { toast } from "react-toastify";

interface Props {
   show: boolean;
   onHide: () => void;
   deviceItem: IDevice;
}

const DeviceModal: React.FC<Props> = ({ show, onHide, deviceItem }): JSX.Element => {
   const handleCopy = () => toast.success("JSON copied successfully");

   const getStringData = (data: string): string => {
      const result = data.split("/").slice(3).toString();
      return result;
   };

   return (
      <Modal centered show={show} onHide={onHide} size="lg">
         <Modal.Header closeButton>
            <div className="w-100 d-flex flex-column justify-content-center align-items-center">
               <h4 className="size-26">Device</h4>
               <p className="magentaText size-20 novaBold700">
                  #{" "}
                  <span className="darkText novaRegular">
                     {deviceItem.name ? getStringData(deviceItem.name) : "no code"}
                  </span>
               </p>
            </div>
         </Modal.Header>

         <Modal.Body>
            <pre className={`whiteText ${styles.jsonContainer}`}>{JSON.stringify(deviceItem, null, 2)}</pre>

            <div className="mt-4 w-100 d-flex justify-content-end">
               <CopyToClipboard text={JSON.stringify(deviceItem)} onCopy={handleCopy}>
                  <button className={`d-flex align-items-center justify-content-center pointer ${styles.copyButton}`}>
                     <CopyIcon className="me-2" />
                     <span>Copy</span>
                  </button>
               </CopyToClipboard>
            </div>
         </Modal.Body>
      </Modal>
   );
};

export default DeviceModal;
