import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { errorOccured } from "../../../../Utils/notifications";
import { hexDecode } from "../../../../Utils/hex";
import PulseLoader from "react-spinners/PulseLoader";
import { IPolicy } from "../../../../Interfaces/GlobalInterfaces";
import { Container, Nav, Card } from "react-bootstrap";
import styles from "./PolicyItem.module.scss";
import PolicyIcon from "../../../../Assets/Icons/policy-icon.png";

import Applications from "./Applications/Applications";
import Settings from "./Settings/Settings";

const PolicyItem: React.FC = (): JSX.Element => {
   const [loading, setLoading] = useState(true);
   const [policy, setPolicy] = useState<IPolicy>({});
   const [activeTab, setActiveTab] = useState<string>("apps");

   const params = useParams();

   const getStringData = (data: string): string => {
      const result = data.split("/").slice(3).toString();
      return result;
   };

   const handleSelectTab = (eventKey): void => {
      setActiveTab(eventKey);
   };

   const fetchData = async (): Promise<void> => {
      try {
         const result = await axios.get<IPolicy>(process.env.REACT_APP_HIDR_URL + `/policies/`, {
            params: {
               name: hexDecode(params.id!),
            },
            headers: {
               Authorization: "Bearer " + localStorage.getItem("jwt"),
            },
         });

         if (result.status === 200) {
            setPolicy(result.data);
         }
      } catch (error) {
         if (error && error.response) {
            toast.error(error.response.data.message);
         } else {
            toast.error(errorOccured);
            console.log(error);
         }
      } finally {
         setLoading(false);
      }
   };

   useEffect(() => {
      fetchData();
   }, [params.id]);

   let toRender: JSX.Element;

   if (loading) {
      toRender = <PulseLoader color={"#c702ff"} loading={loading} />;
   } else if (Object.keys(policy).length === 0) {
      toRender = <p className={`size-16 novaMedium mb-0`}>Policy does not exist.</p>;
   } else {
      toRender = (
         <Container fluid>
            {/* general info */}
            <Card className="p-3 mb-3">
               <div className="d-flex align-items-center flex-wrap gap-3">
                  <img src={PolicyIcon} className={styles.noBackground} alt="no-policy" />
                  <div>
                     <p className="text-break">
                        <strong>{policy?.name}</strong>
                     </p>
                     <p>
                        <strong>Policy name:</strong> {getStringData(policy?.name!)}
                     </p>
                     <p>
                        <strong>Version:</strong> {policy?.version}
                     </p>
                  </div>
               </div>
            </Card>
            {/* tabs */}
            <Nav
               className="mb-3"
               onSelect={(eventKey) => handleSelectTab(eventKey)}
               variant="tabs"
               defaultActiveKey="apps"
            >
               <Nav.Item>
                  <Nav.Link eventKey="apps">Applications</Nav.Link>
               </Nav.Item>
               <Nav.Item>
                  <Nav.Link eventKey="settings">Settings</Nav.Link>
               </Nav.Item>
            </Nav>
            {activeTab === "apps" ? (
               <Applications policy={policy} refetchPolicy={fetchData} />
            ) : activeTab === "settings" ? (
               <Settings policy={policy} refetchPolicy={fetchData} />
            ) : null}
         </Container>
      );
   }

   return <div className={`pageContentContainer`}>{toRender}</div>;
};

export default PolicyItem;
