import React, { useState, useEffect } from "react";
import { errorOccured } from "../../Utils/notifications";
import { toast } from "react-toastify";
import axios from "axios";
import PulseLoader from "react-spinners/PulseLoader";
import { useEnterprise } from "../../Context/EnterpriseProivder";
import { Modal } from "react-bootstrap";
import { IPolicy } from "../../Interfaces/GlobalInterfaces";

interface IProps {
   show: boolean;
   onHide: () => Promise<void>;
   policy: IPolicy
}

const EditAppsModal: React.FC<IProps> = ({ show, onHide, policy }): JSX.Element => {
   const [token, setToken] = useState<string>("");
   const [loading, setLoading] = useState<boolean>(true);

   const enterprise = useEnterprise();

   useEffect(() => {
      const fetchData = async (): Promise<void> => {
         try {
            const result = await axios.post(
               process.env.REACT_APP_HIDR_URL + "/web-token",
               {
                  parent: enterprise?.name,
                  requestBody: {
                     parentFrameUrl: window.location.origin,
                  },
               },
               {
                  headers: {
                     Authorization: "Bearer " + localStorage.getItem("jwt"),
                  },
               }
            );
            setToken(result.data.data.value);
         } catch (error) {
            if (error && error.response) {
               toast.error(error.response.data.message);
            } else {
               toast.error(errorOccured);
               console.log(error);
            }
         } finally {
            setLoading(false);
         }
      };

      fetchData();
   }, [enterprise?.name]);

   let toRender: JSX.Element;

   if (loading) {
      toRender = <PulseLoader color={"#c702ff"} loading={loading} />;
   } else if (!token) {
      toRender = <p className={`size-16 novaMedium mb-0`}>Wrong token.</p>;
   } else {
      toRender = (
         <iframe title="myFrame" style={{ height: "600px" }} className={`w-100`} src={`/iframe.html?token=${token}&name=${policy.name}`} />
      );
   }

   return (
      <Modal centered show={show} onHide={onHide} size="xl">
         <Modal.Header>{toRender}</Modal.Header>
      </Modal>
   );
};

export default EditAppsModal;
