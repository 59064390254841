// success messages
export const loginSuccess: JSX.Element = <span id="login-success">You have successfully logged in</span>;

// export const savedSuccess: JSX.Element = <span id="save-edit-success">New configuration saved successfully</span>;

// export const signUpSuccess: JSX.Element = <span id="signup-success">The account has been successfully created</span>;

// export const sendSms: JSX.Element = <span id="send-sms">We sent you the code via SMS</span>;

export const logOutSuccess: JSX.Element = <span id="log-out-success">You have successfully logged out</span>;

export const emailSentSuccess: JSX.Element = <span id="email-sent-success">Email sent successfully</span>;

// Error messages

export const emptyInputsError: JSX.Element = <span id="empty-inputs-error">All fields are required</span>;

export const emailNotValid: JSX.Element = <span id="email-not-valid">Email address is not valid</span>;

export const passwordNotValid: JSX.Element = (
   <span id="psw-not-valid">
      Password should contain at least 8 characters, at least one number and one special character
   </span>
);

export const errorOccured: JSX.Element = <span id="error-occured">An error occured</span>;

export const invalidUsername: JSX.Element = (
   <span id="invalid-username-error">User name must include minimum 2 and maximum 50 characters</span>
);

// export const passwordsNotMatchError: JSX.Element = <span id="psw-not-match-error">Passwords do not match</span>;

// export const termsNotChecked: JSX.Element = <span id="terms-not-checked">You must accept Terms and Conditions</span>;

// export const twoFactorNotSelected: JSX.Element = (
//    <span id="two-fa-not-selected">You did not select a Two Factor Authentication method</span>
// );
