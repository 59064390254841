import React, { useState, useEffect } from "react";
import styles from "./Enterprises.module.scss";
import axios from "axios";
import { toast } from "react-toastify";
import { errorOccured } from "../../../Utils/notifications";
import PulseLoader from "react-spinners/PulseLoader";
import { useNavigate } from "react-router-dom";
import { routes } from "../../../Utils/routes";
import { IEnterprise } from "../../../Interfaces/GlobalInterfaces";
import { useSetEnterprise } from "../../../Context/EnterpriseProivder";

const Enterprises: React.FC = (): JSX.Element => {
   const [enterpriseList, setEnterpriseList] = useState<IEnterprise[]>([]);
   const [loading, setLoading] = useState<boolean>(true);

   const navigate = useNavigate();
   const setEnterprise = useSetEnterprise();

   const handleShowDevices = (enterprise) => {
      if (setEnterprise) setEnterprise(enterprise);
      navigate(routes.devices);
   };

   useEffect(() => {
      const fetchData = async () => {
         try {
            const result = await axios.get<IEnterprise[]>(process.env.REACT_APP_HIDR_URL + "/enterprises/list", {
               headers: {
                  Authorization: "Bearer " + localStorage.getItem("jwt"),
               },
            });

            setEnterpriseList(result.data);
         } catch (error) {
            if (error && error.response) {
               toast.error(error.response.data.message);
            } else {
               toast.error(errorOccured);
               console.log(error);
            }
         } finally {
            setLoading(false);
         }
      };

      fetchData();
   }, []);

   let toRender: JSX.Element;

   if (loading) {
      toRender = <PulseLoader color={"#c702ff"} loading={loading} />;
   } else if (enterpriseList.length === 0) {
      toRender = <p className={`size-16 novaMedium mb-0`}>There are no enterprises.</p>;
   } else {
      toRender = (
         <div>
            <h2 className={`mb-5 text-center nova-bold size-40 novaBold900 textMagentaGradient`}>Choose enterprise</h2>
            <div className="d-flex justify-content-center flex-wrap gap-3">
               {enterpriseList.map((enterprise) => (
                  <div
                     key={enterprise.enterpriseDisplayName}
                     onClick={() => handleShowDevices(enterprise)}
                     className={`${styles.enterpriseBox} d-flex flex-column justify-content-center align-items-center`}
                  >
                     <div className={`${styles.avatar} size-18 novaMedium`}>
                        <p className={`mb-0`}>{enterprise.enterpriseDisplayName.substring(0, 2).toUpperCase()}</p>
                     </div>
                     <p className={`size-16 novaMedium whiteText mb-0`}>{enterprise.enterpriseDisplayName}</p>
                  </div>
               ))}
            </div>
         </div>
      );
   }

   return (
      <div className={styles.enterprisePage}>
         <div>{toRender}</div>
      </div>
   );
};

export default Enterprises;
