interface AppRoutes {
   signIn: string;
   forgotPassword: string;
   enterprises: string;
   devices: string;
   appManagement: string;
   policies: string;
   policy: string;
   generateEnrollmentToken: string;
   errorPage: string;
}

export const routes: AppRoutes = {
   signIn: "/",
   forgotPassword: "/forgot-password",
   enterprises: "/dashboard/enterprises",
   devices: "/dashboard/devices",
   appManagement: "/dashboard/appManagement",
   policies: "/dashboard/policies",
   policy: "/dashboard/policy",
   generateEnrollmentToken: "/dashboard/generate-enrollment-token",
   errorPage: "/error",
};
